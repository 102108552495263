body {
  font-family: 'Roboto';
}

.react-swipeable-view-container {
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.material__table {
  position: relative;
  &-preloader {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.error {
  font-size: 14px;
  padding: 5px 0;
  color: #ff0000;
}

.wrap {
  margin: 30px 0;
  padding: 0 10px;
}

.p10 {
  padding: 10px;
}

.text-center {
  text-align: center;
}
